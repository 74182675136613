import React from 'react';
import PropTypes from 'prop-types';
import Ripple from 'material-ripple-effects';

export default function NavItem({ children, active, ripple, ...rest }) {
    const rippleEffect = new Ripple();

    return (
        <li
            {...rest}
            className={`${active === 'dark' && 'bg-amber-900'} ${
                active === 'light' && 'bg-white bg-opacity-0'
            } sm:px-5 px-2 sm:py-2 flex gap-1 items-center sm:text-md text-sm uppercase text-white rounded-lg font-body hover:text-amber-300 active:text-black`}
            onMouseUp={(e) => {
                ripple === 'dark' && rippleEffect.create(e, 'dark');
                ripple === 'light' && rippleEffect.create(e, 'light');
            }}
        >
            {children}
        </li>
    );
}

NavItem.propTypes = {
    children: PropTypes.node.isRequired,
    active: PropTypes.string,
    className: PropTypes.string,
    ripple: PropTypes.string,
};