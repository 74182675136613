import React from 'react';
import ContentWrapper from 'components/ui/content-wrapper';
import { Form, Formik } from 'formik';
import useSWR from 'swr';
import * as Yup from 'yup';
import { fetcherFull, fetcherSimple } from 'utils/api';
import FormikInputIcon from 'components/form/formik-input-icon';
import { Button } from '@material-tailwind/react';
import { toast } from 'react-toastify';
import FormikInput from 'components/form/formik-input';

export default function Profile() {
    const {
        data: user,
        error: UserError,
        mutate: userMutate,
    } = useSWR([`/v2/user`, 'GET'], ([path, method]) => fetcherSimple(path, method));

    const validationSchemaUser = Yup.object().shape({
        name: Yup.string().optional(),
        password: Yup.string().optional(),
        confirm_password: Yup.string().optional().oneOf([Yup.ref('password')], "Passwords don't match").required('Confirm Password is required'),
    });


    const handleUserSubmit = async (values) => {
        delete values.confirm_password;
        const userSubmitToast = toast.loading('Saving Survey...');
        const response = await fetcherFull(`/v2/user`, 'PUT', values);
        if (!values.password || values.password === '') {
            delete values.password;
        }
        if (response.success && response.success === false) {
            toast.update(userSubmitToast, {render: `Error updating User, ${response.error || 'unknown error'}`, type: 'error', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            console.log(response.error);
        } else {
            toast.update(userSubmitToast, {render: 'User Updated', type: 'success', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            userMutate(`/v2/user`);
        }
    }

    return (
        <>
            <ContentWrapper>
            <div className='pt-6 py-2 mb-6  md:max-w-[50%]'>
            <h1 className='md:text-5xl text-2xl font-display text-black py-2 md:pl-6 pl-2 rounded-xl md:rounded-3xl bg-gray-100 border-b-2 lg:border-b-2 border-blue-400'>Profile</h1>
                </div>
                <div className='md:max-w-[50%]'>
                {(user &&
                    <Formik initialValues={{
                        name: user.name || '',
                        password: '',
                    }}
                        onSubmit={handleUserSubmit} validationSchema={validationSchemaUser}>
                        {({isValid, dirty}) => (
                            <Form>
                                <div className="my-4 rounded-xl p-4 bg-white font-body break-after-column">
                                    <div className="py-4">
                                        <FormikInputIcon name="name" type="text" color="gray" className='font-body'
                                            label="Name" iconName="badge" autoComplete="off" />
                                    </div>
                                    <div className="pb-4">
                                        <FormikInputIcon name="password" type="password" color="gray" className='font-body'
                                            label="New Password" iconName="lock" autoComplete="new-password" />
                                    </div>
                                    <div className="pb-4">
                                        <FormikInput name="confirm_password" type="password" color="gray" className='font-body'
                                            label="Confirm New Password" autoComplete="new-password" />
                                    </div>
                                    <div className='flex flex-row-reverse'>
                                    <Button className="rounded-full py-1 px-4 font-body 
                                            font-thin normal-case 
                                            hover:text-white border border-gray-700 hover:bg-black text-lg"
                                            type="submit"
                                            disabled={!(dirty) || !(isValid)} variant="filled" color="white" size="lg">
                                            Save
                                    </Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
                    <div>

                    </div>
                </div>
            </ContentWrapper>
        </>
    );
}
