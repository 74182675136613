import ContentWrapper from 'components/ui/content-wrapper';

export default function Dashboard() {
    return (
        <>
            <ContentWrapper>
                <div className='pt-6 py-2 mb-6 md:max-w-[50%]'>
                <h1 className='md:text-5xl text-2xl font-display text-black py-2 md:pl-6 pl-2 rounded-xl md:rounded-3xl bg-gray-100 border-b-2 lg:border-b-2 border-blue-400'>Dashboard</h1>
                </div>

                <div className="mb-10 p-2 text-start">
                    <p className="text-lg font-body">Dashboard content and pre-generated ststistics page.</p>
                </div>
                <div className="">
                </div>
            </ContentWrapper>
        </>
    );
}
