import {useEffect, useState} from "react";
import {Card, CardBody, CardFooter, Checkbox, 
    Button, Alert, Typography, Dialog, DialogHeader, DialogBody, DialogFooter} from '@material-tailwind/react';
import {Form, Formik} from 'formik';
import FormikInputIcon from "../../components/form/formik-input-icon";
import * as Yup from 'yup';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, Link} from "react-router-dom";
import {authSelector, fetchSession, login, totpSetup, totpVerify} from "../../store/auth";
import Image from 'components/image';
import logo from 'assets/img/logos/logo.png';
import { fetcherSimple } from "utils/api";
import FormikInput from "components/form/formik-input";
import { toast } from "react-toastify";

export default function Login() {
    const auth = useSelector(authSelector)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showTOTPDialog, setShowTOTPDialog] = useState(false);
    const [showSetupTOTPDialog, setShowSetupTOTPDialog] = useState(false);
    const [qrCodeUrl, setQrCodeURL] = useState(null);
    const [secretCode, setSecretCode] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    const handleLoginSubmit = async (values) => {
        try {
            const loginResponse = await dispatch(login(values)).unwrap();
            console.log(loginResponse.next);
            if (loginResponse.next && loginResponse.next === 'SETUP_TOTP') {
                const response = await fetcherSimple('/v2/auth/setup-totp', 'GET');
                if (response.secret) {
                    setQrCodeURL(response.qr_url);
                    setSecretCode(response.secret);
                    setShowSetupTOTPDialog(true);
                }
            } else if (loginResponse.next && loginResponse.next === 'CHALLENGE_TOTP') {
                setShowTOTPDialog(true);
            }
        } catch (rejectedValueOrSerializedError) {
            console.log('rejectedValueOrSerializedError');
            toast.error('Error with logging in to server');
        }
    }

    const handleTOTPVerifySubmit = async (values) => {
        dispatch(totpVerify(values));
    }

    const handleTOTPSetupSubmit = async (values) => {
        const submitValues = {
            secret: secretCode,
            code: values.code
        }
        dispatch(totpSetup(submitValues));
    }

    const initialLoginValues = {
        email: '',
        password: '',
    }

    const initialTOTPVerifyValues = {
        code: '',
    }

    const initialTOTPSetupValues = {
        code: '',
    }

    const validationSchemaLogin = Yup.object().shape({
        email: Yup.string().required('Please enter a valid login'),
        password: Yup.string().required('Please provide a password'),
    });

    const validationSchemaVerifyTOTP = Yup.object().shape({
        code: Yup.string().required('Authentication Code Required'),
    });

    const validationSchemaSetupTOTP = Yup.object().shape({
        code: Yup.string().required('Authentication Code Required'),
    });

    useEffect(() => {
        // Redirect if logged in, else fetch session and see if logged in
        if (auth.status === 'loggedIn') {
            navigate('/');
        } else if (auth.status === 'unknown') {
            dispatch(fetchSession());
        }
    }, [auth.status, dispatch, navigate]);

    return (
        <>
        <div className="flex flex-col min-w-screen min-h-screen bg-amber-500">
            <div className='basis-0 h-[200px] md:basis-1/3 justify-left items-start pl-6 pt-3'>
            <Image className="sm:w-[200px] w-[0px]" src={logo} rounded={false} raised={false} alt={"Gobby Logo"}/>
            </div>
            <div className="flex grow justify-center">
                <Card  className="filter-none bg-transparent"  shadow={false}>
                    <Typography className="font-display text-center md:text-2xl lg:content-center md:mt-2" color="black">
                        Sign in to Start Using Gobby®
                    </Typography>                   
                    <Formik initialValues={initialLoginValues} onSubmit={handleLoginSubmit} validationSchema={validationSchemaLogin}>
                        {({isValid, dirty}) => (
                            <Form>
                                <CardBody className="font-body">
                                    <div className="mb-4 px-2 py-2 bg-white rounded-2xl border-gray-700 border">
                                        <FormikInputIcon variant="standard" className="font-body" name="email" type="email" 
                                            color="gray" style={{"borderRadius": 0}} label="Email Address" iconName="email" 
                                            required/>
                                    </div>
                                    <div className="mb-4 px-2 py-2 bg-white rounded-2xl border-gray-700 border">
                                        <FormikInputIcon name="password" className="font-display" variant="standard" 
                                            type={showPassword ? 'text' : 'password'} color="gray" style={{"borderRadius": 0}} label="Password" 
                                            iconName="lock" required/>
                                    </div>
                                    <div className="mb-2 p-4 flex-col flex items-center bg-amber-200 rounded-3xl">
                                        
                                        <Checkbox 
                                            checked={showPassword}
                                            label="Show Password" id="showpassword"
                                            onChange={()=>{setShowPassword(showPassword=>!showPassword)}}
                                        />
                                    {auth.error && (
                                        <Alert color={"red"}>{auth.errorMessage}</Alert>
                                    )}
                                    <p>
                                    <Link className='text-indigo-500 hover:text-orangesoda text-body' to='/resetpassword'>
                                        Click here if you forgot your details.
                                    </Link>
                                    </p>
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <div className="flex justify-center bg-bb">
                                        <Button type="submit" color={isValid && dirty ? "green" : "gray"}
                                                variant="filled"  
                                                className="rounded-full h-24 w-24 font-display text-2xl text-white bg-merlingreen"
                                                disabled={!(isValid && dirty)}>
                                            Go
                                        </Button>
                                    </div>
                                </CardFooter>
                            </Form>
                        )}
                    </Formik>
                    <Typography className="flex justify-center pt-2 bg-bb font-display" variant="h5" color="black" style={{margin: 10}}>New to Gobby®? 
                    <Link className='text-white rounded-full bg-black ml-2 px-4 hover:bg-amber-500 hover:border-black hover:border border-amber-500 border hover:text-black text-body' to='/resetpassword'>
                        &nbsp;Create a New Account
                    </Link></Typography>
                </Card>
            </div>
        </div>
        <Dialog open={showSetupTOTPDialog} handler={() => setShowSetupTOTPDialog(showSetupTOTPDialog=>!showSetupTOTPDialog)}>
            <DialogHeader className="bg-gray-300 text-black font-display rounded-t-lg min-w-full">
                Setup Two-Factor Authentication
            </DialogHeader>
            <Formik initialValues={initialTOTPSetupValues}
                    onSubmit={handleTOTPSetupSubmit}
                    validationSchema={validationSchemaSetupTOTP}>
                {({isValid, dirty}) => (
                    <Form>
                        <DialogBody divider className="mb-7 overflow-y-auto max-h-[80vh]">
                            {(qrCodeUrl &&
                                <Image src={qrCodeUrl} rounded={false} raised={false} />
                            )}
                            <Typography className="font-body">
                                {secretCode ? `Either scan the QR Code or enter as a Secret key into the Authenticator App: ${secretCode}` : 'Secret Code Loading or Unavailable'}
                            </Typography>
                            <div className="px-4 font-body">
                            <FormikInput name="code" autoComplete="off" className="font-display bg-white" type="text" color="indigo"
                                    label="Two Factor Authentication Code" required/>
                            </div>
                        </DialogBody>
                        <DialogFooter>
                            <div className='absolute bottom-2 right-6'>
                                <Button type="submit" color={isValid && dirty ? "indigo" : "gray"}
                                        variant="filled"
                                        className='bg-black rounded-full hover:scale-110 hover:bg-amber-500 hover:text-black'  
                                        disabled={!(isValid && dirty)}
                                        onClick={() => setShowSetupTOTPDialog(false)}>
                                    Submit
                                </Button>
                            </div>
                        </DialogFooter>
                    </Form>
                )}
            </Formik>
        </Dialog>
        <Dialog open={showTOTPDialog} handler={() => setShowTOTPDialog(showTOTPDialog=>!showTOTPDialog)} className='text-center max-w-fit min-w-fit rounded-xl flex flex-col items-center'>
            <DialogHeader className="bg-gray-300 text-black font-display rounded-t-lg min-w-full">
                Verify Your Identity
            </DialogHeader>
            <Formik initialValues={initialTOTPVerifyValues}
                    onSubmit={handleTOTPVerifySubmit}
                    validationSchema={validationSchemaVerifyTOTP}>
                {({isValid, dirty}) => (
                    <Form>
                        <DialogBody className="mb-7 font-body overflow-y-auto max-h-[80vh] md:px-14">
                            <div className="font-body">
                            <Typography className='font-body text-sm pb-4 mt-2'>
                                Enter the passcode using your authenticator application
                            </Typography>
                                <FormikInput autoComplete="off" className="font-display text-lg bg-white" name="code" type="text" color="indigo"
                                    label="Two Factor Authentication Code" required/>
                            </div>
                        </DialogBody>
                        <DialogFooter>
                            <div className="absolute bottom-4 right-4">
                                <Button type="button" 
                                        className="
                                        bg-black
                                        rounded-full 
                                        text-lg 
                                        px-6 py-2
                                        mr-4
                                        hover:bg-amber-500
                                        hover:text-black
                                        font-body normal-case"
                                        variant="filled" 
                                        onClick={() => setShowTOTPDialog(false)}>
                                    cancel
                                </Button>
                                <Button type="submit" color={isValid && dirty ? "indigo" : "gray"}
                                        className="
                                        bg-green-700
                                        rounded-full 
                                        text-lg 
                                        px-6 py-2
                                        hover:bg-amber-500
                                        hover:text-black
                                        font-body normal-case"
                                        variant="filled"
                                        disabled={!(isValid && dirty)}
                                        onClick={() => setShowTOTPDialog(false)}>
                                    Submit
                                </Button>
                            </div>
                        </DialogFooter>
                    </Form>
                )}
            </Formik>
        </Dialog>
        </>
    );
}
