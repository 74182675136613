import React, { useCallback, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { Accordion, AccordionBody, AccordionHeader, Button, TabPanel, Typography } from '@material-tailwind/react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { fetcherFile, fetcherSimple } from 'utils/api';
import IconAcc from 'components/accordion-icon';
import useSWR from 'swr';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { BasicTooltip } from '@nivo/tooltip';

export default function ReportsTab({ tabValue, survey, surveyId, rawSurveyReport, themesSurveyReport
 }) {
    //AccordionControls
    const [open, setOpen] = useState(0);     
    const handleOpen = (value) => setOpen(open === value ? 0 : value);
    // Charts Charts
    const [pieCompletionData, setPieCompletionData] = useState([]);
    const [pieTimeData, setPieTimeData] = useState([]);
    const [barResponseAmountData, setBarResponseAmountData] = useState([]);
    const [barResponseAmountHeight, setBarResponseAmountHeight] = useState(20);
    const [barQuestionsRespondedData, setBarQuestionsRespondedData] = useState([]);
    const [barQuestionsRespondedHeight, setBarQuestionsRespondedHeight] = useState(20);
    const [barThemesAnswersData, setBarThemesAnswersData] = useState([]);
    const [barThemesAnswersHeight, setBarThemesAnswersHeight] = useState(20);
    const [barThemesAnswersLeftMargin, setBarThemesAnswersLeftMargin] = useState(80);
    const [barThemesResponsesData, setBarThemesResponsesData] = useState([]);
    const [barThemesResponsesHeight, setBarThemesResponsesHeight] = useState(20);
    const [barThemesResponsesLeftMargin, setBarThemesResponsesLeftMargin] = useState(80);
    const [barThemesQuestionsData, setBarThemesQuestionsData] = useState([]);
    const [barThemesQuestionsDataKeys, setBarThemesQuestionsDataKeys] = useState([]);
    const [barThemesQuestionsHeight, setBarThemesQuestionsHeight] = useState(20);
    // Insight Ranking Data
    const [completionData, setCompletionData] = useState();
    const [topThemesInsight, setTopThemesInsight] = useState([]);
    const [popularAnswerInsight, setPopularAnswerInsight] = useState('');
    const [mostCommonAnswersInsight, setMostCommonAnswersInsight] = useState([]);

    useEffect(() => {
        const rawData = cloneDeep(rawSurveyReport);
        if (rawData?.report?.[0]?.[0]) {
            // Responses per Q
            const surveyReport = rawData.report.sort((a, b) =>  a.question_position - b.question_position);
            const surveyReportProcessed = surveyReport.flat().map(q => {
                const question = cloneDeep(q);
                const responses = question.responses;
                let processedResponses = [];
                responses.forEach((answer) => {
                    const responseArrayIndex = processedResponses.findIndex(r => r?.Response_ID === answer.Response_ID);
                    if (responseArrayIndex > -1) {
                        processedResponses[responseArrayIndex].themes.push({
                            theme_id: answer.Response_theme_ID,
                            theme_name: answer.Response_theme
                        })
                    } else {
                        const newIndex = processedResponses.push({
                            Recipient_UID_Survey_submission_timestamp: answer.Recipient_UID_Survey_submission_timestamp,
                            Answer_text: answer.Answer_text,
                            Recipient_UID_Creation_timestamp: answer.Recipient_UID_Creation_timestamp,
                            Answer_timestamp: answer.Answer_timestamp,
                            Answer_Type: answer.Answer_Type,
                            Is_Hidden: answer.Is_Hidden,
                            Response_ID: answer.Response_ID,
                            Recipient_UID: answer.Recipient_UID,
                            Answer_UID: answer.Answer_UID,
                            Answer_counter: answer.Answer_counter,
                            themes: []
                        });
                        if (answer.Response_theme_ID) {
                            processedResponses[newIndex-1].themes = [{
                                theme_id: answer.Response_theme_ID,
                                theme_name: answer.Response_theme
                            }]
                        }
                    }
                });
                question.responses = processedResponses;
                return question;
            });
            const barData = surveyReportProcessed.map(q => {
                const obj = {};
                obj.question_position = q.Question_Position;
                obj.question_name = q.Question;
                obj.responses = q.responses?.length;
                return obj;
            });
            const barDataSorted = barData.sort((a, b) =>  a.question_position - b.question_position);
            let barDataFormatted = barDataSorted;
            if (barDataFormatted) {
                setBarQuestionsRespondedData(barDataFormatted);
                setBarQuestionsRespondedHeight(barDataFormatted * 5);
            }
            // survey completion data
            // Respondents & their responses by Q
            const allResponsesOnly = surveyReportProcessed.map((q) => {
                return q.responses;
            }).flat();
            let allRespondentsData = {
                respondents: [],
                total_respondents: 0,
                respondents_finished: 0,
            };
            allResponsesOnly.forEach((answer) => {
                const respondentId = answer.Recipient_UID;
                const respondentArrayIndex = allRespondentsData.respondents.findIndex(t => t?.respondent_id === respondentId);
                if (respondentArrayIndex > -1) {
                    allRespondentsData.respondents[respondentArrayIndex].responses.push({
                        response_id: answer.Response_ID,
                        response_text: answer.Answer_text
                    });
                    allRespondentsData.respondents[respondentArrayIndex].responses_count++;
                } else {
                    allRespondentsData.respondents.push({
                        respondent_id: respondentId,
                        survey_start_time: answer.Recipient_UID_Creation_timestamp,
                        survey_completion_time: answer.Recipient_UID_Survey_submission_timestamp,
                        responses_count: 1,
                        responses: [{
                            response_id: answer.Response_ID,
                            response_text: answer.Answer_text
                        }]
                    });
                    allRespondentsData.total_respondents++;
                    if (answer.Recipient_UID_Survey_submission_timestamp) {
                        allRespondentsData.respondents_finished++;
                    }
                }
            });
            // Set Completion Pie Chart
            if (allRespondentsData.respondents.length) {
                setCompletionData(allRespondentsData);
                let surveyCompletionTimeAverageFinished = []; 
                let surveyCompletionTimeAverageNotFinished = [];
                allRespondentsData.respondents.forEach(respondent => {
                    if (respondent?.survey_completion_time) {
                        surveyCompletionTimeAverageFinished.push(
                            dayjs.duration(dayjs(respondent.survey_completion_time, 'YYYY-MM-DD HH:mm:ss').diff(dayjs(respondent.survey_start_time, 'YYYY-MM-DD HH:mm:ss'))).asMilliseconds()
                        );
                    } else {
                        const surveyLeft = (survey.state === 'finished' ? dayjs(survey.end_date)?.format('YYYY-MM-DDTHH:mm') : dayjs());
                        surveyCompletionTimeAverageNotFinished.push(
                            dayjs.duration(dayjs(surveyLeft).diff(dayjs(respondent.survey_start_time, 'YYYY-MM-DD HH:mm:ss'))).asMilliseconds()
                        );
                    }

                });
                const respondentsCompletionArray = [
                    {
                        id: 'Completed',
                        label: 'Completed',
                        value: allRespondentsData.respondents_finished
                    },
                    {
                        id: 'Unfinished',
                        label: 'Unfinished',
                        value: (allRespondentsData.total_respondents - allRespondentsData.respondents_finished)
                    }
                ];
                const timeAverage = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;
                const respondentsTimeArray = [
                    {
                        id: 'Completed',
                        label: 'Completed',
                        value: timeAverage(surveyCompletionTimeAverageFinished)
                    },
                    {
                        id: 'Unfinished',
                        label: 'Unfinished',
                        value: timeAverage(surveyCompletionTimeAverageNotFinished)
                    }
                ];
                setPieCompletionData(respondentsCompletionArray);
                setPieTimeData(respondentsTimeArray);
            }
            // Respondents & their responses by Q
            const allRespondentsByQ = surveyReportProcessed.map((q) => {
                const responses = q?.responses;
                let respondentsArray = [];
                responses.forEach((answer) => {
                    const respondentId = answer.Recipient_UID;
                    const respondentArrayIndex = respondentsArray.findIndex(t => t?.respondent_id === respondentId);
                    if (respondentArrayIndex > -1) {
                        respondentsArray[respondentArrayIndex].responses.push({ response_id: answer.Response_ID });
                        respondentsArray[respondentArrayIndex].responses_count++;
                    } else {
                        respondentsArray.push({
                            respondent_id: respondentId,
                            survey_start_time: answer.Recipient_UID_Creation_timestamp,
                            survey_completion_time: answer.Recipient_UID_Survey_submission_timestamp,
                            responses_count: 1,
                            responses: [{
                                response_id: answer.Response_ID,
                            }]
                        });
                    }
                });
                return respondentsArray;
            });
            let respondentResponseAmount = [];
            allRespondentsByQ.flat().forEach(questionResponses => {
                let respondenseAmountIndex = respondentResponseAmount.findIndex(t => t?.responses_count === questionResponses.responses_count);
                if (respondenseAmountIndex > -1) {
                    respondentResponseAmount[respondenseAmountIndex].respondents++;
                } else {
                    respondentResponseAmount.push({
                        responses_count: questionResponses.responses_count,
                        respondents: 1,
                        label: `Respondents who gave ${questionResponses.responses_count} Responses`
                    });
                }
            })
            if (respondentResponseAmount.length) {
                setBarResponseAmountData(respondentResponseAmount);
                setBarResponseAmountHeight(respondentResponseAmount.length * 5);
            }
            // Responses by theme per Q
            let themeAnswersKeys = [];
            let themeResponseArray = [];
            const allResponsesbyThemePerQ = surveyReportProcessed.map((q) => {
                const responses = q?.responses;
                let questionThemeResponsesArray = [];
                responses.forEach((response) => {
                    if (response?.themes?.length) {
                        response.themes.forEach((theme) => {
                            const responseThemeIndex = themeResponseArray.findIndex(t => t?.id === theme.theme_id);
                            const questionResponseThemeIndex = questionThemeResponsesArray.findIndex(t => t?.theme_id === theme.theme_id);
                            if (responseThemeIndex > -1) {
                                themeResponseArray[responseThemeIndex].responses_count++;
                            } else {
                                themeResponseArray.push({
                                    id: theme.theme_id,
                                    name: theme.theme_name,
                                    responses_count: 1
                                });
                            }
                            if (questionResponseThemeIndex > -1) {
                                questionThemeResponsesArray[questionResponseThemeIndex].responses_count++;
                            } else {
                                questionThemeResponsesArray.push({
                                    theme_id: theme.theme_id,
                                    theme_name: theme.theme_name,
                                    responses_count: 1
                                });
                            }
                            const themeKey = themeAnswersKeys.find(t => t === theme.theme_name);
                            if (!themeKey) {
                                themeAnswersKeys.push(theme.theme_name)
                            }
                        })
                    }
                });
                let questionThemes = {
                    question_name: q.Question,
                    question_position: q.Question_Position,
                    themes: questionThemeResponsesArray,
                    theme_count: 0
                }
                questionThemeResponsesArray.forEach(theme => {
                    const theme_name = theme.theme_name;
                    if (questionThemes[theme_name]) {
                        questionThemes[theme_name].theme_count++;
                    } else {
                        questionThemes[theme_name] = theme.responses_count;
                    }
                })
                return questionThemes;
            });
            const allResponsesbyThemePerPerQSorted = allResponsesbyThemePerQ.sort((a, b) => b.question_position - a.question_position);
            let topThemes = undefined;
            let longestThemeLength = 0;
            if (themeResponseArray.length) {
                topThemes = themeResponseArray.sort((a, b) => a.responses_count - b.responses_count).slice(0, 3);
                longestThemeLength = themeResponseArray.reduce(
                    function (a, b) {
                        return a.name.length > b.name.length ? a : b;
                    }
                );
            }
            if (themeResponseArray.length) {
                if (topThemes) {setTopThemesInsight(topThemes)};
                setBarThemesResponsesData(themeResponseArray);
                setBarThemesResponsesHeight(themeResponseArray.length * 4);
                setBarThemesResponsesLeftMargin(longestThemeLength.name.length * 8);
            }
            if (allResponsesbyThemePerPerQSorted && themeAnswersKeys) {
                setBarThemesQuestionsData(allResponsesbyThemePerPerQSorted);
                setBarThemesQuestionsDataKeys(themeAnswersKeys);
                setBarThemesQuestionsHeight(allResponsesbyThemePerPerQSorted.length * 5);
            }
        }
    }, [rawSurveyReport, survey.end_date, survey.state]);

    useEffect(() => {
        const themeData = cloneDeep(themesSurveyReport);
        if (themeData) {
            let themesAnswersArray = [];
            themeData.report.forEach((answer) => {
                const answerThemes = answer.themes;
                if (Object.keys(answerThemes).length) {
                    for (const property in answerThemes) {
                        let themesAnswerIndex = themesAnswersArray.findIndex(t => t?.id === property);
                        if (themesAnswerIndex > -1) {
                            themesAnswersArray[themesAnswerIndex]?.answers.push(answer.answer);
                            themesAnswersArray[themesAnswerIndex].answers_count++;
                        } else {
                            themesAnswersArray.push({
                                id: property,
                                name: answerThemes[property],
                                answers: [answer.answer],
                                answers_count: 1
                            });
                        }
                    }
                }
            });
            let longestThemeLength = 0;
            if (themesAnswersArray.length) {
                longestThemeLength = themesAnswersArray.reduce(
                    function (a, b) {
                        return a.name.length > b.name.length ? a : b;
                    }
                );
            }
            if (themesAnswersArray && (longestThemeLength > 0)) {
                setBarThemesAnswersHeight(themesAnswersArray.length * 4);
                setBarThemesAnswersLeftMargin(longestThemeLength.name.length * 8);
                setBarThemesAnswersData(themesAnswersArray);
            }
        }
    }, [themesSurveyReport]);

    const csvDownloadHandler = async (type) => {
        const reportTypes = ['dump', 'summary', 'raw', 'themes'];
        if (!(reportTypes.includes(type))) {
            toast.error('Invalid Report Format!');
            return;
        }
        const csvDownloadToast = toast.loading('Downloading CSV...');
        const fileName = `${survey.name}_${type}_report_${dayjs().format('YYYYMMDD-HHmm')}.csv`;
        const response = fetcherFile(
            `/v2/survey/${surveyId}/report/${type}.csv`, 'GET', fileName
        );
        if (response && !response.error) {
            toast.update(csvDownloadToast, {
                render: 'CSV Downloaded', type: 'success', isLoading: false, autoClose: 5000,
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
            });
        } else if (response && response.error) {
            toast.update(csvDownloadToast, {
                render: `Error Downloading CSV: ${response.error}`, type: 'error', isLoading: false, autoClose: 5000,
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
            });
        } else if (response && response.errors) {
            response.errors.map((error) => {
                toast.update(csvDownloadToast, {
                    render: `Error Downloading CSV: ${error}`, type: 'error', isLoading: false, autoClose: 5000,
                    pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
                });
                return error;
            });
        } else {
            toast.update(csvDownloadToast, {
                render: 'Error Downloading CSV', type: 'error', isLoading: false, autoClose: 5000,
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
            });
        }
    };

    return (
        <>

            <TabPanel value={tabValue} className='bg-gray-200 rounded-xl border border-gray-700 md:px-5 md:py-4'>
                <div className="">
                    <Typography
                        variant="h3"
                        className="text-black font-display pt-2">
                        Insights, Charts and Raw Survey Reports
                    </Typography>
                    <Typography
                        className="text-black">
                        The sections below contain insights, charts and download links for your survey.<br />
                        </Typography>
                        <Typography className='font-body text-blue-gray-600 rounded-lg border-b-gray-700 bg-white pl-6 mt-2 border py-1 text-sm'>
                        &bull; Insights are a quick overview of the most pertinent information.<br />
                        &bull; Charts are split into 'General Data', 'Themes Data' and 'Demographic Data'. Charts are live views of the data collected in your survey results.<br />
                        &bull; The data downloads are raw data available in a selection of output variations, all data is in CSV format.
                    </Typography>
                    <Accordion open={open === 1} icon={<IconAcc id={1} open={open} />}>
                        <AccordionHeader className="bg-white px-4 py-4 text-2xl rounded-lg hover:border-b-blue-300 border border-gray-400 border-b-2 border-b-gray-500 hover:bg-gray-300 md:mt-4 mt-2"
                            onClick={() => handleOpen(1)}>
                            Insights
                        </AccordionHeader>
                        <AccordionBody className="py-1 rounded-lg mt-1 border border-gray-700 border-b-2 bg-white border-b-gray-500 px-4 pb-10">                            
                            <Typography as="span" className='font-body text-lg text-black w-full border border-gray-700 rounded-2xl p-4 my-4 text-center bg-white leading-10'>
                                <Typography variant="h5" className='mb-2'>General Insights</Typography>
                                Your survey has had: <span className=' px-2 py-1 border border-blue-gray-700 rounded-lg bg-blue-100 font-bold'>{completionData?.total_respondents || 0} total visitors</span><br />
                                Of which <span className=' px-2 py-1 border border-blue-gray-700 rounded-lg bg-blue-100 font-bold'>{completionData?.respondents_finished || 0} visitors</span> finished the survey, 
                                and <span className=' px-2 py-1 border border-blue-gray-700 rounded-lg bg-blue-100 font-bold'>{(completionData?.total_respondents - completionData?.respondents_finished) || 0} visitors</span> left it uncompleted.<br />
                                In total <span className=' px-2 py-1 border border-blue-gray-700 rounded-lg bg-blue-100 font-bold'>{completionData?.respondents_finished || 0}</span> people completed the survey.
                            </Typography>
                            <Typography as="span" className='font-body text-lg text-black w-full border border-gray-700 rounded-2xl p-4 my-2 text-center bg-white leading-10'>
                                <Typography variant="h5" className='mb-2'>Theme Insights</Typography>
                                The top three themes in this survey were:<br />
                                <span className=' px-2 py-1 border border-blue-gray-700 rounded-lg bg-blue-100 font-bold'>{topThemesInsight?.[0]?.name || 'N/A'}</span><br />
                                <span className=' px-2 py-1 border border-blue-gray-700 rounded-lg bg-blue-100 font-bold'>{topThemesInsight?.[1]?.name || 'N/A'}</span><br />
                                <span className=' px-2 py-1 border border-blue-gray-700 rounded-lg bg-blue-100 font-bold'>{topThemesInsight?.[2]?.name || 'N/A'}</span>
                            </Typography>
                            {/* Data is currently unavailable */}
                            {/* <Typography className='font-body text-lg text-black w-full border border-gray-700 rounded-2xl p-4 my-4 text-center bg-white leading-loose'>
                                The most popular answer in the survey was:<br />
                                <span className=' px-2 py-1 border border-blue-gray-700 rounded-lg bg-blue-gray-100 font-bold'>!BEST RESPONSE!</span><br />
                                It was given to question: !QUESTIONNumber!: !QUESTION!
                            </Typography> */}
                            {/* Needs figuring out */}
                            {/* <Typography className='font-body text-lg text-black w-full border border-gray-700 rounded-2xl p-4 my-4 text-center bg-white leading-loose'>
                                The most common three answers, in descending order, to !QUESTIONNUMBER!: !DEMOGRAPHICQUESTION! were:<br />
                                <span className=' px-2 py-1 border border-blue-gray-700 rounded-lg bg-blue-gray-100 font-bold'>!DEMOGRAPHIC RESPONSE!</span><br />
                                <span className=' px-2 py-1 border border-blue-gray-700 rounded-lg bg-blue-gray-100 font-bold'>!DEMOGRAPHIC RESPONSE!</span><br />
                                <span className=' px-2 py-1 border border-blue-gray-700 rounded-lg bg-blue-gray-100 font-bold'>!DEMOGRAPHIC RESPONSE!</span>
                                </Typography> */}
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 2} icon={<IconAcc id={2} open={open} />}>
                        <AccordionHeader className="bg-white px-4 py-4 text-2xl hover:border-b-blue-300 rounded-lg border border-gray-400 border-b-2 border-b-gray-500 hover:bg-gray-300 mt-4"
                            onClick={() => handleOpen(2)}>
                            Charts: General Data
                        </AccordionHeader>
                        <AccordionBody className="bg-white py-1 rounded-lg mt-1 border border-gray-400 border-b-2 border-b-gray-500 px-4">                            
                            <Typography variant='h4' className='mt-4 ml-2 text-blue-gray-700'>
                                Survey Completion
                            </Typography>
                            <Typography as='span' className='mt-2 ml-2'>
                                The following chart shows the number of respondents who completed the survey versus the ones who left the survey unfinished.
                                Highlight a bar with your mouse pointer (roll over with the mouse or press and hold with a touch screen) for more
                                information.
                            </Typography>
                            {(pieCompletionData &&
                            <div className='h-80 mb-10'>
                                <ResponsivePie
                                    data={pieCompletionData}
                                    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                    innerRadius={0.5}
                                    padAngle={1.5}
                                    cornerRadius={3}
                                    activeOuterRadiusOffset={8}
                                    borderWidth={1}
                                    colors={{ scheme: 'nivo' }}
                                    borderColor={{
                                        from: 'color',
                                        modifiers: [
                                            [
                                                'darker',
                                                .5
                                            ]
                                        ]
                                    }}
                                    arcLinkLabelsSkipAngle={10}
                                    arcLinkLabelsTextColor="#333333"
                                    arcLinkLabelsThickness={2}
                                    arcLinkLabelsColor={{ from: 'color' }}
                                    arcLabelsSkipAngle={10}
                                    arcLabelsTextColor={{
                                        from: 'color',
                                        modifiers: [
                                            [
                                                'darker',
                                                2
                                            ]
                                        ]
                                    }}
                                    defs={[
                                        {
                                            id: 'dots',
                                            type: 'patternDots',
                                            background: 'inherit',
                                            color: 'rgba(255, 255, 255, 0.3)',
                                            size: 4,
                                            padding: 1,
                                            stagger: true
                                        },
                                        {
                                            id: 'lines',
                                            type: 'patternLines',
                                            background: 'inherit',
                                            color: 'rgba(255, 255, 255, 0.3)',
                                            rotation: -45,
                                            lineWidth: 6,
                                            spacing: 10
                                        }
                                    ]}
                                    fill={[
                                        {
                                            match: {
                                                id: 'Completed'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'Unfinished'
                                            },
                                            id: 'lines'
                                        },
                                    ]}
                                    legends={[
                                        {
                                            anchor: 'bottom',
                                            direction: 'row',
                                            justify: false,
                                            translateX: 0,
                                            translateY: 56,
                                            itemsSpacing: 0,
                                            itemWidth: 100,
                                            itemHeight: 18,
                                            itemTextColor: '#999',
                                            itemDirection: 'left-to-right',
                                            itemOpacity: 1,
                                            symbolSize: 18,
                                            symbolShape: 'circle',
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemTextColor: '#000'
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                />
                            </div>
                            )}
                            <Typography variant='h4' className='mt-4 ml-2 border-t border-gray-300 pt-2 text-blue-gray-700'>
                                Time Spent on Survey
                            </Typography>
                            <Typography as='span' className='mt-2 ml-2'>
                                This chart shows the average time to complete a survey versus the average time an unfinished survey was left. 
                                All times are rounded to the nearest minute.
                                Highlight a bar with your mouse pointer (roll over or press and hold with a touch screen) for more
                                information.
                            </Typography>
                            {(pieTimeData &&
                            <div className='h-80 mb-10'>
                                <ResponsivePie
                                    data={pieTimeData}
                                    valueFormat={value => dayjs.duration(value).humanize()}
                                    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                    innerRadius={0.5}
                                    padAngle={1.7}
                                    cornerRadius={3}
                                    activeOuterRadiusOffset={8}
                                    borderWidth={1}
                                    colors={{ scheme: 'nivo' }}
                                    borderColor={{
                                        from: 'color',
                                        modifiers: [
                                            [
                                                'darker',
                                                0.2
                                            ]
                                        ]
                                    }}
                                    arcLinkLabelsSkipAngle={10}
                                    arcLinkLabelsTextColor="#333333"
                                    arcLinkLabelsThickness={2}
                                    arcLinkLabelsColor={{ from: 'color' }}
                                    arcLabelsSkipAngle={10}
                                    arcLabelsTextColor={{
                                        from: 'color',
                                        modifiers: [
                                            [
                                                'darker',
                                                2
                                            ]
                                        ]
                                    }}
                                    defs={[
                                        {
                                            "id": "squares",
                                            "type": "patternSquares",
                                            "size": 4,
                                            "padding": 4,
                                            "stagger": false,
                                            "background": "#e2dcc6",
                                            "color": "#ecebda"
                                        },
                                        {
                                            id: 'lines',
                                            type: 'patternLines',
                                            background: 'inherit',
                                            color: 'rgba(255, 255, 255, 0.3)',
                                            rotation: -45,
                                            lineWidth: 6,
                                            spacing: 10
                                        }
                                    ]}
                                    fill={[
                                        {
                                            match: {
                                                id: 'Completed'
                                            },
                                            id: 'squares'
                                        },
                                        {
                                            match: {
                                                id: 'Unfinished'
                                            },
                                            id: 'lines'
                                        },
                                    ]}
                                    legends={[
                                        {
                                            anchor: 'bottom',
                                            direction: 'row',
                                            justify: false,
                                            translateX: 0,
                                            translateY: 56,
                                            itemsSpacing: 0,
                                            itemWidth: 100,
                                            itemHeight: 18,
                                            itemTextColor: '#999',
                                            itemDirection: 'left-to-right',
                                            itemOpacity: 1,
                                            symbolSize: 18,
                                            symbolShape: 'circle',
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemTextColor: '#000'
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                />
                            </div>
                            )}
                            <Typography variant='h4' className='mt-4 ml-2 border-t border-gray-300 pt-2 text-blue-gray-700'>
                                Number of Responses by Question
                            </Typography>
                            <Typography as='span' className='mt-2 ml-2'>
                                This chart shows the number of responses given on each question. Some questions allow for multiple answers so the numbers will
                                often exceed the total number of survey respondents. The vertical axis shows the question position number, the horizontal axis
                                shows the number of responses. Highlight a bar with your mouse pointer (roll over with the mouse or press and hold with a touch screen) to see
                                the question text and more information.
                            </Typography>
                            {(barQuestionsRespondedData &&
                            <div className='h-80' style={{height: `${barQuestionsRespondedHeight}rem`}}>
                                <ResponsiveBar
                                    colorBy="indexValue"
                                    data={barQuestionsRespondedData}
                                    indexBy="question_position"
                                    keys={['responses']}
                                    layout='horizontal'
                                    margin={{ top: 10, right: 10, bottom: 80, left: 80 }}
                                    padding={0.3}
                                    valueScale={{ type: 'linear' }}
                                    indexScale={{ type: 'band', round: true }}
                                    colors={{ scheme: 'nivo' }}
                                    borderColor={
                                        {
                                            from: 'color',
                                            modifiers: [
                                                [
                                                    'darker',
                                                    1.5
                                                ]
                                            ]
                                        }
                                        }
                                    borderWidth={1}
                                    borderRadius={4}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 3,
                                        tickRotation: 0,
                                        legend: 'Number of Responses',
                                        legendPosition: 'middle',
                                        legendOffset: 40,
                                        truncateTickAt: 0,
                                        format: e => Math.floor(e) === e && e
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: 'Question Position (Number)',
                                        legendPosition: 'middle',
                                        legendOffset: -60,
                                        truncateTickAt: 0
                                    }}
                                    tooltip={(bar) => {
                                        return (
                                            // See https://github.com/plouc/nivo/blob/master/packages/tooltip/src/BasicTooltip.tsx
                                            // for the formatting
                                            <BasicTooltip
                                            id={bar.data.question_name}
                                            value={bar.formattedValue}
                                            color={bar.color}
                                            enableChip
                                            />
                                        )
                                    }}
                                    role="application"
                                    ariaLabel="Responses per Question Bar Chart"
                                />
                            </div>
                            )}
                            <Typography variant='h4' className='mt-4 ml-2 border-t border-gray-300 pt-2 text-blue-gray-700'>
                                Number  of Responses given for each Question
                            </Typography>
                            <Typography as='span' className='mt-2 ml-2'>
                                This chart shows the number of responses to each question. Gobby® allows for multiple responses to some questions,
                                especially the 'open-style' Gobby® question. The vertical axis shows the number of responses given, and the horizontal 
                                the number of times that response occurred. So if there was a single response, given 12 times it would appear as a 1 on 
                                the vertical axis and 12 on the horizontal, i.e. a single response was given 12 times.  Highlight a bar with your mouse pointer 
                                (roll over or press and hold with a touch screen) for more information.
                            </Typography>
                            {(barResponseAmountData &&
                            <div className='mb-10' style={{height: `${barResponseAmountHeight}rem`}}>
                                <ResponsiveBar
                                    colorBy="indexValue"
                                    data={barResponseAmountData}
                                    indexBy="responses_count"
                                    keys={['respondents']}
                                    layout='horizontal'
                                    margin={{ top: 10, right: 10, bottom: 80, left: 80 }}
                                    padding={0.3}
                                    valueScale={{ type: 'linear' }}
                                    indexScale={{ type: 'band', round: true }}
                                    colors={{ scheme: 'nivo' }}
                                    borderColor={
                                        {
                                            from: 'color',
                                            modifiers: [
                                                [
                                                    'darker',
                                                    1.5
                                                ]
                                            ]
                                        }
                                        }
                                    borderWidth={1}
                                    borderRadius={4}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 3,
                                        tickRotation: 0,
                                        legend: 'Number of Respondents',
                                        legendPosition: 'middle',
                                        legendOffset: 40,
                                        format: e => (Number.isInteger(e) ? e : ''),
                                        truncateTickAt: 0
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: 'Number of Responses',
                                        legendPosition: 'middle',
                                        legendOffset: -60,
                                        truncateTickAt: 0
                                    }}
                                    tooltip={(bar) => {
                                        return (
                                            <BasicTooltip
                                                id={bar.data.label}
                                                value={bar.formattedValue}
                                                color={bar.color}
                                                enableChip
                                            />
                                        )
                                    }}
                                    role="application"
                                    ariaLabel="Responses per Question Bar Chart"
                                />
                            </div>
                            )}
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 3} icon={<IconAcc id={3} open={open} />}>
                        <AccordionHeader className="bg-white px-4 py-4 text-2xl rounded-lg border hover:border-b-blue-300 border-gray-400 border-b-2 border-b-gray-500 hover:bg-gray-300 mt-4"
                            onClick={() => handleOpen(3)}>
                            Charts: Themes Data
                        </AccordionHeader>
                        <AccordionBody className="bg-white py-1 rounded-lg mt-1 border border-gray-400 border-b-2 border-b-gray-500 px-4">
                        <Typography
                                variant='h4'
                                className='mt-4 ml-2 text-blue-gray-700'>
                                    Number of Responses by Theme
                            </Typography>
                            <Typography as="span" className='mb-6 ml-2'>
                                A count of themes on the responses to answers.
                                The vertical axis shows the theme name and the horizonal axis the number of times that theme has been
                                 applied to a response, from any question. This chart quickly views the dominant themes in your survey.
                                Highlight a bar with your pointer (roll over with the mouse or press and hold with a touch screen) for more
                                information.
                            </Typography>
                                {( (barThemesResponsesData.length && barThemesResponsesHeight && barThemesResponsesLeftMargin) &&
                                <div className='mb-10' style={{height: `${barThemesResponsesHeight}rem`}}>
                                <ResponsiveBar
                                    colorBy="indexValue"
                                    data={barThemesResponsesData}
                                    indexBy="name"
                                    keys={[`responses_count`]}
                                    layout='horizontal'
                                    margin={{ top: 10, right: 10, bottom: 80, left: barThemesResponsesLeftMargin }}
                                    padding={0.3}
                                    valueScale={{ type: 'linear' }}
                                    indexScale={{ type: 'band', round: true }}
                                    colors={{ scheme: 'nivo' }}
                                    borderColor={
                                        {
                                            from: 'color',
                                            modifiers: [
                                                [
                                                    'darker',
                                                    1.5
                                                ]
                                            ]
                                        }
                                      }
                                    borderWidth={1}
                                    borderRadius={4}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 3,
                                        tickRotation: 0,
                                        legend: 'Number of Responses',
                                        legendPosition: 'middle',
                                        legendOffset: 40,
                                        truncateTickAt: 0
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: 'Theme',
                                        legendPosition: 'middle',
                                        legendOffset: -180,
                                        truncateTickAt: 0
                                    }}
                                    tooltip={(bar) => {
                                        return (
                                            <BasicTooltip
                                            id={bar.data.name}
                                            value={bar.formattedValue}
                                            color={bar.color}
                                            enableChip
                                            />
                                        )
                                    }}
                                    role="application"
                                    ariaLabel="Responses per Theme Bar Chart"
                                />
                            </div>
                            )}

                            <Typography
                                variant='h4'
                                className='mt-4 ml-2 border-t border-gray-300 pt-2 text-blue-gray-700'>
                                    Number of Answers that share a Theme
                            </Typography>
                            <Typography as="span" className='mb-6 ml-2'>
                                A count of the number of times a theme has been applied to an answer, not based on the number of responses
                                that theme has been applied to. The vertical axis shows the theme, the horizontal axis the number of
                                distinct answers that have the theme.
                                Highlight a bar with your mouse pointer (roll over with the mouse or press and hold with a touch screen) for more
                                information.
                            </Typography>
                            {( (barThemesAnswersData.length && barThemesAnswersHeight && barThemesAnswersLeftMargin) &&
                            <div className='mb-10' style={{height: `${barThemesAnswersHeight}rem`}}>
                            <ResponsiveBar
                                colorBy="indexValue"
                                data={barThemesAnswersData}
                                indexBy="name"
                                keys={[`answers_count`]}
                                layout='horizontal'
                                margin={{ top: 10, right: 10, bottom: 80, left: barThemesAnswersLeftMargin }}
                                padding={0.3}
                                valueScale={{ type: 'linear' }}
                                indexScale={{ type: 'band', round: true }}
                                colors={{ scheme: 'nivo' }}
                                borderColor={
                                    {
                                        from: 'color',
                                        modifiers: [
                                            [
                                                'darker',
                                                1.5
                                            ]
                                        ]
                                    }
                                }
                                borderWidth={1}
                                borderRadius={4}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'Number of Answers',
                                    legendPosition: 'middle',
                                    legendOffset: 40,
                                    truncateTickAt: 0
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'Theme',
                                    legendPosition: 'middle',
                                    legendOffset: -180,
                                    truncateTickAt: 0
                                }}
                                tooltip={(bar) => {
                                    return (
                                        <BasicTooltip
                                        id={bar.data.name}
                                        value={bar.formattedValue}
                                        color={bar.color}
                                        enableChip
                                        />
                                    )
                                }}
                                role="application"
                                ariaLabel="Answers per Theme Bar Chart"
                                />
                            </div>
                            )}
                            <Typography
                                variant='h4'
                                className='mt-4 ml-2 border-t border-gray-300 pt-2 text-blue-gray-700'>
                                    Number of Responses by Theme per Question
                            </Typography>
                            <Typography as="span" className='mb-6 ml-2'>
                                This is a stacked chart showing two types of information in each bar. The vertical axis
                                shows the question position in the survey as a number, the horizontal position shows the 
                                total number of theme responses. The individual boxes in the stack on the bar show a count of an individual theme. 
                                To see the theme name and the question detail simply highlight a bar with your pointer (roll over with the
                                mouse or press and hold with a touch screen), and this will show more information.
                            </Typography>
                            {( (barThemesQuestionsData.length && barThemesQuestionsDataKeys.length && barThemesQuestionsHeight) &&
                            <>
                                <div className="mb-10" style={{height: `${barThemesQuestionsHeight}rem`}}>
                                    <ResponsiveBar
                                        data={barThemesQuestionsData}
                                        indexBy="question_position"
                                        keys={barThemesQuestionsDataKeys}
                                        layout='horizontal'
                                        margin={{ top: 10, right: 10, bottom: 80, left: 80 }}
                                        padding={0.3}
                                        valueScale={{ type: 'linear' }}
                                        indexScale={{ type: 'band', round: true }}
                                        colors={{ scheme: 'nivo' }}
                                        borderColor={
                                            {
                                                from: 'color',
                                                modifiers: [
                                                    [
                                                        'darker',
                                                        1.5
                                                    ]
                                                ]
                                            }
                                        }
                                        borderWidth={1}
                                        borderRadius={2}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 3,
                                            tickRotation: 0,
                                            legend: 'Number of responses by theme',
                                            legendPosition: 'middle',
                                            legendOffset: 40,
                                            truncateTickAt: 0
                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Question Position (Number)',
                                            legendPosition: 'middle',
                                            legendOffset: -60,
                                            truncateTickAt: 0
                                        }}
                                        tooltip={(bar) => {
                                            return (
                                                // See https://github.com/plouc/nivo/blob/master/packages/tooltip/src/BasicTooltip.tsx
                                                // for the formatting
                                                <BasicTooltip
                                                    id={`${bar.data.question_name}`}
                                                    value={bar.id}
                                                    color={bar.color}
                                                    enableChip
                                                />
                                            )
                                        }}
                                        role="application"
                                        ariaLabel="Responses per Question Bar Chart"
                                    />
                                </div>
                            </>
                            )}
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 4} icon={<IconAcc id={4} open={open} />}>
                        <AccordionHeader className="bg-white px-4 py-4 text-2xl rounded-lg border hover:border-b-blue-300 border-gray-400 border-b-2 border-b-gray-500 hover:bg-gray-300 mt-4"
                            onClick={() => handleOpen(4)}>
                            Charts: Demographics Data
                        </AccordionHeader>
                        <AccordionBody className="bg-white py-1 rounded-lg mt-1 border border-gray-400 border-b-2 border-b-gray-500 px-4">
                            <div className='p-2 mb-2'>
                                <Typography
                                    variant='h6'
                                    className=''>
                                        Charts to be added!
                                </Typography>
                            </div>
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 5} icon={<IconAcc id={5} open={open} />}>
                        <AccordionHeader className="bg-white px-4 py-4 text-2xl z-10 rounded-lg hover:border-b-blue-300 border border-gray-400 border-b-2 border-b-gray-500 hover:bg-gray-300 mt-4"
                            onClick={() => handleOpen(5)}>
                            Survey: Data Downloads
                        </AccordionHeader>
                        <AccordionBody className="bg-white py-1 z-50 rounded-lg mt-1 border border-gray-400 border-b-2 border-b-gray-500 px-4">
                            <div className='p-2 mb-2'>
                                <Typography as="div" className="font-body">
                                    Downloads the result of the survey in CSV Format, choose a report layout to download. Please note that an 'All Data' download is a very large 
                                    file containing all data in a long data format of one response per line 
                                    (multiple responses of the same type *will* appear on multiple lines).
                                </Typography>
                                <Typography className="mt-4">
                                    <Button
                                        type="button"
                                        variant="filled"
                                        color="white"
                                        size="lg"
                                        className="mb-2 rounded-full py-1 px-4 font-body font-thin text-lg normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 border border-gray-700 mr-4"
                                        onClick={() => csvDownloadHandler('dump')}>
                                        Response Data
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="filled"
                                        color="white"
                                        size="lg"
                                        className="mb-2 rounded-full py-1 px-4 font-body font-thin text-lg normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 border border-gray-700 mr-4"
                                        onClick={() => csvDownloadHandler('summary')}>
                                        Answer Summary
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="filled"
                                        color="white"
                                        size="lg"
                                        className="mb-2 rounded-full py-1 px-4 font-body font-thin text-lg normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 border border-gray-700 mr-4"
                                        onClick={() => csvDownloadHandler('themes')}>
                                        Themes
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="filled"
                                        color="white"
                                        size="lg"
                                        className="mb-2 rounded-full py-1 px-4 font-body font-thin text-lg normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 border border-gray-700 mr-4"
                                        onClick={() => csvDownloadHandler('raw')}>
                                        All Data
                                    </Button>
                                </Typography>
                            </div>
                        </AccordionBody>
                    </Accordion>
                </div>
            </TabPanel>
        </>
    );
}
